import React, { useEffect, useState } from 'react';
import supabase from '../config/supabaseClient';
import NavigationBar from '../components/NavigationBar';
import EditCampaignModal from '../components/EditCampaignModal';

const AdminPage = ({ token, setToken }) => {
  const [fetchError, setFetchError] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCampaign, setCurrentCampaign] = useState(null);

  useEffect(() => {
    const fetchCampaigns = async () => {
      if (!token || !token.userId) {
        setFetchError('User not authenticated');
        return;
      }

      const { data, error } = await supabase
        .from('campaign')
        .select()
        .eq('user_id', token.userId);

      if (error) {
        setFetchError('Could not fetch campaigns');
        setCampaigns([]);
        console.error('Error fetching campaigns:', error);
      } else {
        setCampaigns(data);
        setFetchError(null);
      }
    };

    fetchCampaigns();
  }, [token]);

  const handleEdit = (campaignId) => {
    const campaignToEdit = campaigns.find(campaign => campaign.id === campaignId);
    setCurrentCampaign(campaignToEdit);
    setIsModalOpen(true);
  };

  const handleSave = async (updatedCampaign) => {
    const { data, error } = await supabase
      .from('campaign')
      .update(updatedCampaign)
      .eq('id', updatedCampaign.id)
      .eq('user_id', token.userId)
      .select();

    if (error) {
      console.error('Error updating campaign:', error);
    } else if (data) {
      setCampaigns(campaigns.map(campaign =>
        campaign.id === updatedCampaign.id ? data[0] : campaign
      ));
      setIsModalOpen(false);
      setCurrentCampaign(null);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentCampaign(null);
  };

  return (
    <div className="admin-page">
      <NavigationBar token={token} setToken={setToken} />
      <div className="admin-content">
        <h1>My Campaigns</h1>
        {fetchError && <p className="error">{fetchError}</p>}
        {campaigns.length > 0 ? (
          <table className="admin-campaign-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Image</th>
                <th>Goal</th>
                <th>Description</th>
                <th>Country</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {campaigns.map(campaign => (
                <tr key={campaign.id}>
                  <td>{campaign.name}</td>
                  <td>
                    <img
                      src={campaign.images}
                      alt={campaign.name}
                      className="admin-campaign-image"
                    />
                  </td>
                  <td>£{campaign.goal}</td>
                  <td>{campaign.statement}</td>
                  <td>{campaign.country}</td>
                  <td>
                    <button className="admin-edit-button" onClick={() => handleEdit(campaign.id)}>Edit</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No campaigns found, you need to be signed in</p>
        )}
      </div>

      {isModalOpen && currentCampaign && (
        <EditCampaignModal
          campaign={currentCampaign}
          onClose={handleCloseModal}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export default AdminPage;
