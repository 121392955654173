import { Link, useNavigate } from 'react-router-dom';

function Hero({ token, setToken }) {
    const navigate = useNavigate();

    const handleLogout = () => {
        setToken(null);
        navigate('/auth');
    };

    const handleStartCampaignClick = () => {
        if (!token) {
            navigate('/auth?redirect=/campaign');
        } else {
            navigate('/campaign');
        }
    };

    return (
        <div className="nav-background">
            <nav className="navbar">
                <div className="navbar-left">
                    <Link to="/my-page" className="nav-link search-btn">
                        <img
                            src={require("../images/Search.png")}
                            alt="My Page"
                            className="icon"
                        />
                        My Page
                    </Link>
                    <Link to="/leaderboard" className="nav-link leaderboard-btn">
                        <img
                            src={require("../images/Leaderboard.png")}
                            alt="Leaderboard"
                            className="icon"
                        />
                        Leaderboard
                    </Link>
                </div>
                <div className="navbar-center">
                    <h1 className="logo">DonatingHub</h1>
                </div>
                <div className="navbar-right">
                    {token ? (
                        <>
                            <button className="nav-link logout-btn" onClick={handleLogout}>
                                Logout
                            </button>
                        </>
                    ) : (
                        <Link to="/auth" className="nav-link login-btn">
                            Login/Sign up
                        </Link>
                    )}
                    <button className="campaign-btn" onClick={handleStartCampaignClick}>
                        Start a Campaign
                    </button>
                </div>
                <div className="bottom-button-container">
                    <button className="bottom-btn" onClick={handleStartCampaignClick}>
                        Start a Campaign
                    </button>
                </div>
            </nav>
        </div>
    );
}

export default Hero;
