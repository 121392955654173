import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import supabase from "../config/supabaseClient";
import NavigationBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import DummyChatbox from "../components/DummyChatBox";
import '../giveout.css';

function GiveOutSection({ token, setToken }) {
  const { giveoutId } = useParams();
  const [giveout, setGiveOut] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const [showChatbox, setShowChatbox] = useState(false);

  useEffect(() => {
    const fetchGiveOut = async () => {
      const { data, error } = await supabase
        .from("giveout")
        .select()
        .eq("id", giveoutId)
        .single();

      if (error) {
        setFetchError("Could not fetch the giveout details");
        console.log(error);
      }
      if (data) {
        setGiveOut(data);
        setFetchError(null);
      }
    };

    fetchGiveOut();
  }, [giveoutId]);

  const toggleChatbox = () => {
    setShowChatbox(!showChatbox);
  };

  if (fetchError) return <p>{fetchError}</p>;
  if (!giveout) return <p>Loading...</p>;

  return (
    <div className="giveout-section-container">
      <NavigationBar token={token} setToken={setToken} />
      <main className="giveout-content">
        <h1>{giveout.item}</h1>
        <div className="giveout-layout">
          <div className="giveout-main">
            <img src={giveout.images} alt={giveout.item} className="main-image" />
            <p>{giveout.name} is giving away this item</p>
            <p className="description">{giveout.description}</p>
          </div>
          <div className="giveout-sidebar">
            <div className="donor-info">
              <img src={giveout.profile_picture} alt="Donor Profile" className="donor-profile-picture" />
              <div>
                <h3>{giveout.name}</h3>
              </div>
            </div>
            <div className="giveout-stats">
              <p>Condition: {giveout.condition}</p>
            </div>
            <button className="contact-button" onClick={toggleChatbox}>Contact Donor</button>
          </div>
        </div>
      </main>
      {showChatbox && <DummyChatbox onClose={toggleChatbox} />}
      <Footer />
    </div>
  );
}

export default GiveOutSection;