import React, { useState, useEffect } from 'react';
import { Upload } from 'lucide-react';
import axios from 'axios';

const EditGiveoutModal = ({ giveout, onClose, onSave }) => {
    const [formData, setFormData] = useState({
        item: '',
        images: '',
        condition: '',
        description: '',
        profile_picture: '',
    });

    useEffect(() => {
        if (giveout) {
            setFormData({
                item: giveout.item || '',
                images: giveout.images || '',
                condition: giveout.condition || '',
                description: giveout.description || '',
                profile_picture: giveout.profile_picture || '',
            });
        }
    }, [giveout]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleImageUpload = async (e, type) => {
        const file = e.target.files[0];
        if (file) {
            const imageFormData = new FormData();
            imageFormData.append('file', file);
            imageFormData.append("upload_preset", "avatar");

            try {
                const response = await axios.post(
                    "https://api.cloudinary.com/v1_1/dwyorh97f/image/upload",
                    imageFormData
                );
                setFormData(prevData => ({
                    ...prevData,
                    [type]: response.data.secure_url,
                }));
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave({ ...giveout, ...formData });
    };

    if (!giveout) return null;

    return (
        <div className="modal">
            <div className="modal-content">
                <h2>Edit Giveout</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-grid">
                        <div className="form-left">
                            <label>
                                Item:
                                <input
                                    type="text"
                                    name="item"
                                    value={formData.item}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                            <label>
                                Condition:
                                <input
                                    type="text"
                                    name="condition"
                                    value={formData.condition}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                            <label>
                                Description:
                                <textarea
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                        </div>
                        <div className="form-right">
                            <label className="image-upload">
                                Item Image:
                                <div className="image-upload-area">
                                    {formData.images ? (
                                        <img src={formData.images} alt="Item" className="uploaded-image" />
                                    ) : (
                                        <div className="upload-placeholder">
                                            <Upload size={24} />
                                            <span>Drag or upload your photo here</span>
                                        </div>
                                    )}
                                    <input
                                        type="file"
                                        name="images"
                                        onChange={(e) => handleImageUpload(e, 'images')}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                            </label>
                            <label className="image-upload">
                                Profile Picture:
                                <div className="image-upload-area">
                                    {formData.profile_picture ? (
                                        <img src={formData.profile_picture} alt="Profile" className="uploaded-image" />
                                    ) : (
                                        <div className="upload-placeholder">
                                            <Upload size={24} />
                                            <span>Drag or upload your photo here</span>
                                        </div>
                                    )}
                                    <input
                                        type="file"
                                        name="profile_picture"
                                        onChange={(e) => handleImageUpload(e, 'profile_picture')}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="button-group">
                        <button type="button" className="cancel-button" onClick={onClose}>
                            Cancel
                        </button>
                        <button type="submit" className="save-button">
                            Save Changes
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditGiveoutModal;
