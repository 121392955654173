function HeroSection() {
  return (
    <div>
      <section className="main-content">
        <div className="text-content">
          <h1>
            Donating on DonationHub is engaging, transparent, and
            community-driven
          </h1>
          <p>
            Experience a new way of giving that combines the power of
            gamification, transparency, and peer-to-peer networking. Whether
            you're supporting a cause close to your heart, responding to a
            friend's fundraiser, or exploring new charitable opportunities,
            DonationHub makes donating both fun and impactful. With interactive
            features like leaderboards and badges, real-time tracking of your
            donations' impact, and the ability to connect directly with
            recipients, you'll see exactly how your generosity makes a
            difference. Join our community of givers and experience the joy of
            making a change, one donation at a time.
          </p>
        </div>
        <div className="image-content">
          <img
            src={require("../images/givingg.jpg")}
            alt="Description of the image"
          />
        </div>
      </section>
    </div>
  );
}

export default HeroSection;
