import { useEffect, useState } from "react";
import Profile from "../components/Profile";
import supabase from "../config/supabaseClient";
import { useNavigate} from 'react-router-dom';

function Leaderboard({ setToken }) {
  const [donors, setDonors] = useState([]);
  const [activeButton, setActiveButton] = useState('7');
  const navigate = useNavigate();

  const fetchDonors = async () => {
    let { data, error } = await supabase
      .from('donor_totals')
      .select('*')
      .order('total_donated', { ascending: false }) 
      .limit(10); 

    if (error) {
      console.error("Error fetching data:", error);
      return;
    }

    setDonors(data);
  };

  useEffect(() => {
    fetchDonors();
  }, []);

  const handleClick = (e) => {
    setActiveButton(e.target.dataset.id);
  };

  return (
    <div className="board">
      <h1 className="leaderboard">Leaderboard</h1>
      <button className="back-button" onClick={() => navigate("/")}>Back to Home</button> {/* Back button */}
      <div className="duration">
        <button
          onClick={handleClick}
          data-id="7"
          className={activeButton === '7' ? 'active' : ''}
        >
          7 Days
        </button>
        <button
          onClick={handleClick}
          data-id="30"
          className={activeButton === '30' ? 'active' : ''}
        >
          30 Days
        </button>
        <button
          onClick={handleClick}
          data-id="0"
          className={activeButton === '0' ? 'active' : ''}
        >
          All-Time
        </button>
      </div>
      {donors.length > 0 ? (
        <Profile donors={donors} />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default Leaderboard;
