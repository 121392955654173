import React from 'react';

function DummyChatbox({ onClose }) {
  return (
    <div className="chatbox">
      <div className="chatbox-header">
        <h3>Chat with Donor</h3>
        <button onClick={onClose}>Close</button>
      </div>
      <div className="chatbox-messages">
        <p>This is a dummy chatbox. No real messages here!</p>
      </div>
      <div className="chatbox-input">
        <input type="text" placeholder="Type a message..." />
        <button>Send</button>
      </div>
    </div>
  );
}

export default DummyChatbox;