import React, { useState, useEffect } from "react";
import Hero from "../components/Hero";
import Card from "../components/Card";
import HeroSection from "../components/HeroSection";
import DonateSection from "../components/DonateSection";
import Footer from "../components/Footer";
import GiveOut from "../components/GiveOut";

function HomePage({ token, setToken }) {
    const [showWelcome, setShowWelcome] = useState(false);
    const [userName, setUserName] = useState('');

    useEffect(() => {
        if (token) {
            setShowWelcome(true);
            setUserName(token.name);
            const timer = setTimeout(() => {
                setShowWelcome(false);
            }, 3000); // Display the message for 3 seconds

            return () => clearTimeout(timer);
        }
    }, [token]);

    return (
        <div>
            <Hero token={token} setToken={setToken} />
            {showWelcome && (
                <div className="welcome-message">
                    <p>Welcome back, {userName}! You are logged in.</p>
                </div>
            )}
            <Card />
            <HeroSection />
            <DonateSection />
            <GiveOut />
            <Footer />
        </div>
    );
}

export default HomePage;