import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import '../campaign.css';
import supabase from "../config/supabaseClient";

function Step1({ formData, setFormData, nextStep }) {
  return (
    <div className="campaignpage-container">
      <div className="campaignpage-step-indicator">1 of 5</div>
      <h1 className="campaignpage-h1">Tell us who you're raising funds for</h1>
      <p className="campaignpage-subtitle">This information helps us get to know you and your fundraising needs.</p>
      <div className="campaignpage-form-group">
        <label className="campaignpage-form-label">
          <input
            type="radio"
            className="campaignpage-form-radio"
            value="yourself"
            checked={formData.recipient === "yourself"}
            onChange={(e) => setFormData({ ...formData, recipient: e.target.value })}
          />
          <div className="campaignpage-form-text">
            <div className="campaignpage-form-title">Yourself</div>
            <div className="campaignpage-form-description">Funds are delivered to your bank account for your own use</div>
          </div>
        </label>
        
        <label className="campaignpage-form-label">
          <input
            type="radio"
            className="campaignpage-form-radio"
            value="someone else"
            checked={formData.recipient === "someone else"}
            onChange={(e) => setFormData({ ...formData, recipient: e.target.value })}
          />
          <div className="campaignpage-form-text">
            <div className="campaignpage-form-title">Someone else</div>
            <div className="campaignpage-form-description">You'll invite a beneficiary to receive funds or distribute them yourself</div>
          </div>
        </label>
        
        <label className="campaignpage-form-label">
          <input
            type="radio"
            className="campaignpage-form-radio"
            value="charity"
            checked={formData.recipient === "charity"}
            onChange={(e) => setFormData({ ...formData, recipient: e.target.value })}
          />
          <div className="campaignpage-form-text">
            <div className="campaignpage-form-title">Charity</div>
            <div className="campaignpage-form-description">Funds are delivered to your chosen charity for you</div>
          </div>
        </label>
      </div>
      <div className="campaignpage-button-container">
        <button className="campaignpage-continue-button" onClick={nextStep}>Continue</button>
      </div>
    </div>
  );
}

function Step2({ formData, setFormData, nextStep, prevStep }) {
  const maxTargetAmount = 10000;

  const handleAmountChange = (e) => {
    let value = parseFloat(e.target.value);

    if (isNaN(value)) {
      value = '';
    } else if (value < 0) {
      value = 0;
    } else if (value > maxTargetAmount) {
      value = maxTargetAmount;
    }

    setFormData({ ...formData, targetAmount: value });
  };


  return (
    <div className="campaignpage-step2-container">
      <div className="campaignpage-step2-indicator">2 of 5</div>
      <h1 className="campaignpage-step2-h1">How much would you like to raise?</h1>
      <p className="campaignpage-step2-subtitle">You can always change your target as you go.</p>

      <div className="campaignpage-step2-input-container">
        <span className="campaignpage-step2-currency-symbol">£</span>
        <input
          type="text"
          className="campaignpage-step2-input"
          value={formData.targetAmount}
          onChange={handleAmountChange}
          placeholder="Your initial target"
        />
        <span className="campaignpage-step2-currency-code">GBP</span>
      </div>

      <p className="campaignpage-step2-info">
        Bear in mind that transaction fees, including credit and debit charges, are deducted from each donation.
      </p>

      <div className="campaignpage-step2-requirements">
        <h3>To receive money raised, please make sure the person withdrawing has:</h3>
        <ul>
          <li>A national insurance number</li>
          <li>A bank account in the United Kingdom</li>
        </ul>
      </div>

      <div className="campaignpage-step2-button-container">
        <button className="campaignpage-step2-back-button" onClick={prevStep}>Back</button>
        <button className="campaignpage-step2-continue-button" onClick={nextStep}>Continue</button>
      </div>
    </div>
  );
}

function Step3({ formData, setFormData, nextStep, prevStep }) {
  const [dragActive, setDragActive] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const fileInputRef = useRef(null);

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, coverPhoto: file });
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      setFormData({ ...formData, coverPhoto: file });
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCountryChange = (e) => {
    setFormData({ ...formData, country: e.target.value });
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="step3-container">
      <div className="campaignpage-step2-indicator">3 of 5</div>
      <h2 className="step3-header">Add a cover photo and your country</h2>
      <p className="step3-subheader">Using a bright and clear photo helps people connect to your fundraiser instantly.</p>

      <div
        className={`step3-upload-container ${dragActive ? "step3-drag-active" : ""}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleUploadClick}
      >
        {previewUrl ? (
          <img src={previewUrl} alt="Preview" className="step3-preview-image" />
        ) : (
          <>
            <div className="step3-upload-icon">📁</div>
            <p className="step3-upload-text">
              Drag or upload your photo here
            </p>
          </>
        )}
        <input
          type="file"
          id="coverPhoto"
          name="coverPhoto"
          accept="image/*"
          onChange={handlePhotoChange}
          style={{ display: 'none' }}
          ref={fileInputRef}
        />
      </div>

      <div className="step3-country-container">
        <input
          type="text"
          id="country"
          name="country"
          value={formData.country || ''}
          onChange={handleCountryChange}
          className="step3-country-input"
          placeholder="Enter your country"
        />
      </div>

      <div className="step3-navigation">
        <button className="step3-back-button" onClick={prevStep}>Back</button>
        <button className="step3-continue-button" onClick={nextStep}>Continue</button>
      </div>
    </div>
  );
}
function Step4({ formData, setFormData, nextStep, prevStep }) {
  const handleTitleChange = (e) => {
    setFormData({ ...formData, title: e.target.value });
  };

  const handleDescriptionChange = (e) => {
    setFormData({ ...formData, description: e.target.value });
  };

  return (
    <div className="step4-container">
      <div className="campaignpage-step-indicator">4 of 5</div>
      <div className="step4-left-column">
        <h2 className="step4-header">Tell donors why you're fundraising</h2>
        <p className="step4-subheader">Some ideas to help you start writing:</p>
        <ul className="step4-list">
          <li>Introduce yourself and what you're raising funds for</li>
          <li>Describe why it's important to you</li>
          <li>Explain how the funds will be used</li>
        </ul>
      </div>
      <div className="step4-right-column">
        <div className="step4-form-group">
          <label className="step4-label" htmlFor="title">Give your fundraiser a title</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleTitleChange}
            className="step4-input"
            placeholder="Donate to help..."
            maxLength={60}
          />
          <div className="step4-char-count">{formData.title.length}/60</div>
          {formData.title.length === 0 && (
            <div className="step4-error-message">Please enter a fundraiser title.</div>
          )}
        </div>

        <div className="step4-form-group">
          <label className="step4-label" htmlFor="description">Tell your story</label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleDescriptionChange}
            className="step4-textarea"
            placeholder="Hi, my name is Simon and I'm fundraising for..."
            rows="5"
          ></textarea>
        </div>

        <div className="step4-navigation">
          <button className="step4-back-button" onClick={prevStep}>Back</button>
          <button className="step4-review-button" onClick={nextStep}>Review</button>
        </div>
      </div>
    </div>
  );
}
function Step5({ formData, prevStep, submitForm }) {
  return (
    <div className="step5-container">
      <div className="campaignpage-step-indicator">5 of 5</div>
      <h1 className="step5-header">You're ready to start fundraising</h1>
      <p className="step5-subheader">Preview what your fundraiser will look like to donors or revise any details.</p>

      <div className="step5-review-section">
        <h2 className="step5-section-title">Cover media</h2>
        {formData.coverPhoto ? (
          <img src={URL.createObjectURL(formData.coverPhoto)} alt="Fundraiser cover" className="step5-cover-image" />
        ) : (
          <p>No photo uploaded</p>
        )}
      </div>

      <div className="step5-review-section">
        <h2 className="step5-section-title">Goal</h2>
        <p className="step5-section-content">{formData.targetAmount} GBP</p>
      </div>

      <div className="step5-review-section">
        <h2 className="step5-section-title">Country</h2>
        <p className="step5-section-content">{formData.country}</p>
      </div>

      <div className="step5-review-section">
        <h2 className="step5-section-title">Title</h2>
        <p className="step5-section-content">{formData.title}</p>
      </div>

      <div className="step5-review-section">
        <h2 className="step5-section-title">Story</h2>
        <p className="step5-section-content">{formData.description}</p>
      </div>

      <div className="step5-navigation">
        <button className="step5-preview-button" onClick={prevStep}>Preview</button>
        <button className="step5-launch-button" onClick={submitForm}>Launch campaign</button>
      </div>
    </div>
  );
}

function CampaignPage({ token }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    recipient: "",
    targetAmount: "",
    coverPhoto: null,
    country: "",
    title: "",
    description: ""
  });

  const navigate = useNavigate(); 

  // Use the token to set the default organizer name and user_id
  const organizerName = token ? token.name : "Anonymous";
  const userId = token ? token.userId : null;

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const submitForm = async () => {
    if (!formData.recipient || !formData.targetAmount || !formData.coverPhoto || !formData.country || !formData.title || !formData.description) {
      alert("Please complete all steps before submitting.");
      return;
    }

    let fileUrl = "";
    if (formData.coverPhoto) {
      fileUrl = await uploadImage(formData.coverPhoto);
      if (!fileUrl) {
        alert("Image upload failed. Please try again.");
        return;
      }
    }

    // Insert data into Supabase table
    const { data, error } = await supabase
      .from('campaign')
      .insert([
        {
          organizer: organizerName, // Use the organizer's name from the token
          goal: formData.targetAmount,
          images: fileUrl,
          country: formData.country,
          name: formData.title,
          statement: formData.description,
          user_id: userId // Save the user ID here
        }
      ]);

    if (error) {
      alert("Error submitting form: " + error.message);
      return;
    }

    console.log("Form Submitted", data);
    alert("Campaign created successfully!");
    setFormData({
      recipient: "",
      targetAmount: "",
      coverPhoto: null,
      country: "",
      title: "",
      description: ""
    });
    setCurrentStep(1);

    // Redirect to home page
    navigate("/");
  };

  const uploadImage = async (file) => {
    const imageFormData = new FormData();
    imageFormData.append("file", file);
    imageFormData.append("upload_preset", "avatar");

    try {
      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/dwyorh97f/image/upload",
        imageFormData
      );
      return response.data.secure_url;
    } catch (error) {
      console.error("Error uploading image:", error);
      return null;
    }
  };

  return (
    <div className="campaign-container">
      {currentStep === 1 && <Step1 formData={formData} setFormData={setFormData} nextStep={nextStep} />}
      {currentStep === 2 && <Step2 formData={formData} setFormData={setFormData} nextStep={nextStep} prevStep={prevStep} />}
      {currentStep === 3 && <Step3 formData={formData} setFormData={setFormData} nextStep={nextStep} prevStep={prevStep} />}
      {currentStep === 4 && <Step4 formData={formData} setFormData={setFormData} nextStep={nextStep} prevStep={prevStep} />}
      {currentStep === 5 && <Step5 formData={formData} prevStep={prevStep} submitForm={submitForm} />}
    </div>
  );
}


export default CampaignPage;