import { Link } from 'react-router-dom';

const GiveOutCard = ({ giveout }) => {
    return (
      <Link to={`/giveout/${giveout.id}`} className="giveout-card-link">
        <div className="giveout-card">
          <div className="giveout-image-container">
            <img className="giveout-image" src={giveout.images} alt="item" />
          </div>
          <div className="giveout-content">
            <div className="profile-info">
              <img className="profile-picture" src={giveout.profile_picture} alt="profile" />
              <p className="giveout-name">{giveout.name}</p>
            </div>
            <h3 className="giveout-item">{giveout.item}</h3>
            <p className="giveout-condition">{giveout.condition}</p>
          </div>
        </div>
      </Link>
    );
};

export default GiveOutCard;
