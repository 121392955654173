import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import supabase from "../config/supabaseClient";
import NavigationBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import DonationModal from "../components/DonationModal";
import submitDonation from "../services/donationService";

function CardSection({ token, setToken }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { campaignId } = useParams();
  const [campaign, setCampaign] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handle the donation button click
  const handleDonateClick = () => {
    setIsModalOpen(true);
  };

  // Handle the closing of the modal
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  // Handle the donation submission
  const handleDonationSubmit = async (formData) => {
    if (isSubmitting) return; // Prevent multiple submissions

    try {
      setIsSubmitting(true);

      // Use the updated submitDonation function
      await submitDonation({
        ...formData,
        amount: parseFloat(formData.amount),
        campaignId: campaignId // Pass the campaign ID
      });

      console.log("Donation successfully added!");
      alert("Donation successful!");

      // Update the campaign's total amount raised
      const { data: updatedCampaign, error: updateError } = await supabase
        .from("campaign")
        .update({
          amount_raised: campaign.amount_raised + parseFloat(formData.amount),
        })
        .eq("id", campaignId) // Use the campaignId from the URL
        .select();

      if (updateError) {
        console.error("Error updating campaign data:", updateError);
      } else {
        setCampaign(updatedCampaign[0]);
      }
    } catch (error) {
      console.error("Error during donation submission:", error);
    } finally {
      setIsSubmitting(false);
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    const fetchCampaign = async () => {
      const { data, error } = await supabase
        .from("campaign")
        .select()
        .eq("id", campaignId)
        .single();

      if (error) {
        setFetchError("Could not fetch campaign");
        console.log(error);
      }
      if (data) {
        setCampaign(data);
      }
    };

    const fetchComments = async () => {
      const { data, error } = await supabase
        .from("comments")
        .select()
        .eq("campaign_id", campaignId)
        .order("created_at", { ascending: false });

      if (error) {
        console.log("Error fetching comments:", error);
      } else {
        setComments(data);
      }
    };

    fetchCampaign();
    fetchComments();
  }, [campaignId, token]);

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (comment.trim() === "") return;

    const userName = token?.name || "Anonymous";

    const { data, error } = await supabase
      .from("comments")
      .insert([{ campaign_id: campaignId, user_name: userName, comment }])
      .select();

    if (error) {
      console.log("Error submitting comment:", error);
    } else {
      if (data && data.length > 0) {
        setComments([data[0], ...comments]);
      }
      setComment("");
    }
  };

  if (fetchError) {
    return <p>{fetchError}</p>;
  }

  if (!campaign) {
    return <p>Loading...</p>;
  }

  return (
    <div className="card-section-container">
      <NavigationBar token={token} setToken={setToken} />
      <section className="card-section">
        <div className="campaign-header">
          <h1>{campaign.name}</h1>
        </div>
        <div className="campaign-main">
          <div className="campaign-content">
            <img
              src={campaign.images}
              alt={campaign.name}
              className="campaign-image"
            />
            <div className="organizer-info">
              <p>{campaign.organizer} is organizing this campaign</p>
            </div>
            <hr />
            <p className="campaign-statement">{campaign.statement}</p>
          </div>
          <div className="campaign-sidebar">
            <div className="campaign-progress">
              <p className="amount-raised">
                <span className="raised">£{campaign.amount_raised}</span> raised
                of £{campaign.goal}
              </p>
              <div className="progress-bar">
                <div
                  className="progress"
                  style={{
                    width: `${(campaign.amount_raised / campaign.goal) * 100}%`,
                  }}
                ></div>
              </div>
              <p className="donation-count">
                {campaign.number_of_donors} donations
              </p>
              <div className="action-buttons">
                <button
                  className="donate-btn"
                  onClick={handleDonateClick}
                  disabled={isSubmitting}
                >
                  Donate
                </button>
                <button className="share-btn">Share</button>
              </div>
            </div>
            <div className="organizer-section">
              <h3>
                <b>Organizer</b>
              </h3>
              <div className="organizer-details">
                <img
                  className="organizer-img"
                  src={require("../images/profile.jpeg")}
                  alt="Organizer"
                />
                <div>
                  <h4>{campaign.organizer}</h4>
                  <p>{campaign.country}</p>
                </div>
              </div>
              <button className="contact-btn">Contact</button>
            </div>
          </div>
        </div>
        <hr className="comment-divider" />
        <div className="comment-section">
          <h3>Comments</h3>
          <form onSubmit={handleCommentSubmit}>
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Leave a comment..."
            ></textarea>
            <button type="submit">Post Comment</button>
          </form>
          <div className="existing-comments">
            {comments.map((comment) => (
              <div key={comment.id} className="comment">
                <p>
                  <strong>{comment.user_name}</strong>:
                </p>
                <p>{comment.comment}</p>
                <p>
                  <em>{new Date(comment.created_at).toLocaleString()}</em>
                </p>
              </div>
            ))}
          </div>
        </div>
        <br />
        <Footer />
      </section>

      {/* Donation Modal */}
      <DonationModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handleDonationSubmit}
      />
    </div>
  );
}

export default CardSection;
