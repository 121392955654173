import React, { useEffect, useState } from 'react';
import supabase from '../config/supabaseClient';
import NavigationBar from '../components/NavigationBar';
import EditGiveoutModal from '../components/EditGiveoutModal';

const AdminGiveoutPage = ({ token, setToken }) => {
  const [fetchError, setFetchError] = useState(null);
  const [giveouts, setGiveouts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentGiveout, setCurrentGiveout] = useState(null);

  useEffect(() => {
    const fetchGiveouts = async () => {
      if (!token || !token.userId) {
        setFetchError('User not authenticated');
        return;
      }

      const { data, error } = await supabase
        .from('giveout')
        .select()
        .eq('user_id', token.userId);

      if (error) {
        setFetchError('Could not fetch giveouts');
        setGiveouts([]);
        console.error('Error fetching giveouts:', error);
      } else {
        setGiveouts(data);
        setFetchError(null);
      }
    };

    fetchGiveouts();
  }, [token]);

  const handleEdit = (giveoutId) => {
    const giveoutToEdit = giveouts.find((giveout) => giveout.id === giveoutId);
    setCurrentGiveout(giveoutToEdit);
    setIsModalOpen(true);
  };

  const handleSave = async (updatedGiveout) => {
    const { data, error } = await supabase
      .from('giveout')
      .update(updatedGiveout)
      .eq('id', updatedGiveout.id)
      .eq('user_id', token.userId)
      .select();

    if (error) {
      console.error('Error updating giveout:', error);
    } else if (data) {
      setGiveouts(
        giveouts.map((giveout) =>
          giveout.id === updatedGiveout.id ? data[0] : giveout
        )
      );
      setIsModalOpen(false);
      setCurrentGiveout(null);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentGiveout(null);
  };

  return (
    <div className="admin-page">
      <NavigationBar token={token} setToken={setToken} />
      <div className="admin-content">
        <h1>My Giveouts</h1>
        {fetchError && <p className="error">{fetchError}</p>}
        {giveouts.length > 0 ? (
          <table className="campaign-table">
            <thead>
              <tr>
                <th>Item</th>
                <th>Image</th>
                <th>Condition</th>
                <th>Description</th>
                <th>Profile Picture</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {giveouts.map((giveout) => (
                <tr key={giveout.id}>
                  <td>{giveout.item}</td>
                  <td>
                    <img
                      src={giveout.images}
                      alt={giveout.item}
                      className="campaign-image"
                    />
                  </td>
                  <td>{giveout.condition}</td>
                  <td>{giveout.description}</td>
                  <td>
                    <img
                      src={giveout.profile_picture}
                      alt="Profile"
                      className="campaign-image"
                    />
                  </td>
                  <td>
                    <button
                      className="edit-button"
                      onClick={() => handleEdit(giveout.id)}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No giveouts found, you need to be signed in</p>
        )}
      </div>

      {isModalOpen && currentGiveout && (
        <EditGiveoutModal
          giveout={currentGiveout}
          onClose={handleCloseModal}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export default AdminGiveoutPage;
