// GiveOut.js
import supabase from "../config/supabaseClient";
import React, { useEffect, useState } from "react";
import GiveOutCard from "./GiveOutCard";
import { Link } from 'react-router-dom';

function GiveOut() {
  const [fetchError, setFetchError] = useState(null);
  const [giveouts, setGiveOuts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchGiveOut = async () => {
      const { data, error } = await supabase.from("giveout").select();

      if (error) {
        setFetchError("Could not fetch the giveouts");
        setGiveOuts([]);
        console.log(error);
      }
      if (data) {
        setGiveOuts(data);
        setFetchError(null);
      }
    };

    fetchGiveOut();
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? 0 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 3 >= giveouts.length ? prevIndex : prevIndex + 1));
  };

  return (
    <div className="giveout-section">
      {fetchError && <p>{fetchError}</p>}
      {giveouts.length > 0 && (
        <div className="giveout-wrapper">
          <button onClick={handlePrev} disabled={currentIndex === 0}>Prev</button>
          <div className="giveout-cards">
            {giveouts.slice(currentIndex, currentIndex + 3).map((giveout) => (
              <GiveOutCard key={giveout.id} giveout={giveout} />
            ))}
          </div>
          <button onClick={handleNext} disabled={currentIndex + 3 >= giveouts.length}>Next</button>
        </div>
      )}
      <div className="more-items">
        <Link to="/all-giveouts">More items</Link>
      </div>
    </div>
  );
}

export default GiveOut;
