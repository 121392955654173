import React, { useEffect, useState } from 'react';
import supabase from '../config/supabaseClient';
import CampaignCard from '../components/CampaignCard';
import Footer from "../components/Footer";
import '../AllCampaignsPage.css';
import NavigationBar from '../components/NavigationBar';

const AllCampaignsPage = ({ token,setToken}) => {
  const [fetchError, setFetchError] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const campaignsPerPage = 9; // 3 rows of 3 campaigns each

  useEffect(() => {
    const fetchCampaigns = async () => {
      const { data, error } = await supabase.from('campaign').select();

      if (error) {
        setFetchError('Could not fetch campaigns');
        console.log(error);
      } else {
        setCampaigns(data);
      }
    };

    fetchCampaigns();
  }, []);

  const indexOfLastCampaign = currentPage * campaignsPerPage;
  const indexOfFirstCampaign = indexOfLastCampaign - campaignsPerPage;
  const currentCampaigns = campaigns.slice(indexOfFirstCampaign, indexOfLastCampaign);

 
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="all-campaigns-page">
      <NavigationBar token={token} setToken={setToken} />
      <br></br>
      <br></br>
      {fetchError && <p>{fetchError}</p>}
      <div className="all-campaigns-grid">
        {currentCampaigns.map(campaign => (
          <CampaignCard key={campaign.id} campaign={campaign} className="all-campaign-card" />
        ))}
      </div>
      <div className="pagination">
        <button 
          onClick={() => paginate(currentPage - 1)} 
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="current-page">{currentPage}</span>
        <button 
          onClick={() => paginate(currentPage + 1)} 
          disabled={indexOfLastCampaign >= campaigns.length}
        >
          Next
        </button>
      </div>
      <Footer/>
    </div>
    
  );
};

export default AllCampaignsPage;