import React, { useState, useEffect } from 'react';
import axios from 'axios';
import supabase from '../config/supabaseClient';
import NavigationBar from '../components/NavigationBar';
import Footer from "../components/Footer";
import { useNavigate } from 'react-router-dom';

function GiveOutForm({ token, setToken }) {
  const [avatar, setAvatar] = useState(null);
  const [itemImage, setItemImage] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [condition, setCondition] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const [user, setUser] = useState(null); 
  const navigate = useNavigate(); 

  useEffect(() => {
    if (!token) {
      sessionStorage.setItem('intendedDestination', '/giveout');
      navigate('/auth');
    } else {
      const fetchUserData = async () => {
        try {
          const { data: { user }, error } = await supabase.auth.getUser();
          if (error) throw error;
          setUser(user);
        } catch (error) {
          console.error("Error fetching user data:", error);
          navigate('/auth');
        }
      };
      fetchUserData();
    }
  }, [navigate, token]);

  const handleAvatarChange = (e) => {
    setAvatar(e.target.files[0]);
  };

  const handleItemImageChange = (e) => {
    setItemImage(e.target.files[0]);
  };

  const uploadImage = async (file) => {
    const imageFormData = new FormData();
    imageFormData.append("file", file);
    imageFormData.append("upload_preset", "avatar");

    try {
      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/dwyorh97f/image/upload",
        imageFormData
      );
      return response.data.secure_url;
    } catch (error) {
      console.error("Error uploading image:", error);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);

    if (!avatar || !itemImage || !title || !description || !condition) {
      alert("Please fill in all the fields and upload images.");
      setIsSubmitting(false);
      return;
    }

    const avatarURL = await uploadImage(avatar);
    const itemImageURL = await uploadImage(itemImage);

    if (!avatarURL || !itemImageURL) {
      alert("Failed to upload images. Please try again.");
      setIsSubmitting(false);
      return;
    }

    const { data, error } = await supabase
      .from('giveout')
      .insert([
        {
          item: title,
          description: description,
          images: itemImageURL,
          profile_picture: avatarURL,
          condition: condition,
          name: user.user_metadata.full_name,
          user_id: user.id  
        }
      ]);

    if (error) {
      console.log("Error inserting data:", error);
      alert("Failed to submit the form. Please try again.");
    } else {
      alert("Form submitted successfully!");
      navigate('/');
    }

    setIsSubmitting(false);
  };

  return (
    <div>
      <NavigationBar token={token} setToken={setToken} />
      <div className="giveout-form-container">
        <h2>Give Out an Item</h2>
        <form onSubmit={handleSubmit} className="giveout-form">
          <div className="form-group">
            <label>Upload Avatar</label>
            <div className="file-upload-area">
              <input type="file" onChange={handleAvatarChange} />
            </div>
          </div>

          <div className="form-group">
            <label>Upload Item Picture</label>
            <div className="file-upload-area">
              <input type="file" onChange={handleItemImageChange} />
            </div>
          </div>

          <div className="form-group">
            <label>Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="e.g. White COS Jumper"
            />
          </div>

          <div className="form-group">
            <label>Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="e.g. only worn a few times, true to size"
            />
          </div>

          <div className="form-group">
            <label>Condition</label>
            <input
              type="text"
              value={condition}
              onChange={(e) => setCondition(e.target.value)}
              placeholder="e.g. New, Like New, Used"
            />
          </div>

          <button type="submit" className="submit-button" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </form>
      </div>
      <Footer/>
    </div>
  );
}

export default GiveOutForm;
