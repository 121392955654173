import React, { useState } from 'react';
import CampaignCard from './CampaignCard';
import { Link } from 'react-router-dom';

const PopularCampaigns = ({ campaigns }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const campaignsPerPage = 5;
  const totalCampaigns = campaigns.length;

  const sortedCampaigns = [...campaigns].sort((a, b) => b.amount_raised - a.amount_raised);

  const displayedCampaigns = sortedCampaigns.slice(currentIndex, currentIndex + campaignsPerPage);

  const nextCampaign = () => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex + campaignsPerPage;
      if (nextIndex >= totalCampaigns) {
        return 0; 
      }
      return nextIndex;
    });
  };

  const prevCampaign = () => {
    setCurrentIndex((prevIndex) => {
      const prevIndexCalc = prevIndex - campaignsPerPage;
      if (prevIndexCalc < 0) {
        return Math.max(totalCampaigns - campaignsPerPage, 0); // Wrap around to the end
      }
      return prevIndexCalc;
    });
  };

  return (
    <section className="popular-campaigns">
      <div className="campaigns-header">
        <h2>Popular Campaigns</h2>
        <div className="navigation-buttons">
          <button
            onClick={prevCampaign}
            aria-label="Previous campaign"
            disabled={currentIndex === 0}
          >
            ←
          </button>
          <button
            onClick={nextCampaign}
            aria-label="Next campaign"
            disabled={currentIndex + campaignsPerPage >= totalCampaigns && totalCampaigns > campaignsPerPage}
          >
            →
          </button>
        </div>
      </div>
      <div className="campaign-grid">
        {displayedCampaigns.map((campaign, index) => (
          <CampaignCard
            key={campaign.id}
            campaign={campaign}
            className={index === 0 ? 'main' : `side${index}`}
          />
        ))}
      </div>
      <div className='explore-container'>
        <Link to="/campaigns" className="explore-all">EXPLORE ALL CAMPAIGNS</Link>
      </div>
    </section>
  );
};

export default PopularCampaigns;
