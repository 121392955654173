import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../mypage.css';

function MyPage({ token }) {
    const navigate = useNavigate();

    const handleNavigate = (path) => {
        navigate(path);
    };

    return (
        <div className="my-page">
            <h1>Welcome to Your Page</h1>
            <button className="back-button" onClick={() => navigate("/")}>Back to Home</button> 
            <div className="card-container">
                <div className="card" onClick={() => handleNavigate('/admin')}>
                    <h2>Your Campaigns</h2>
                    <p>View and manage your campaigns.</p>
                </div>
                <div className="card" onClick={() => handleNavigate('/admin-giveouts')}>
                    <h2>Your Giveouts</h2>
                    <p>View and manage your giveouts.</p>
                </div>
            </div>
        </div>
    );
}

export default MyPage;
