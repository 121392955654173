import React from 'react';
import { Link } from 'react-router-dom';

const formatDonors = (number) => {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + "M donations";
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + "k donations";
  } else {
    return number + " donations";
  }
};

const CampaignCard = ({ campaign, className = '' }) => { 
  return (
    <Link to={`/campaign/${campaign.id}`} className={`campaign-card ${className}`}>
      <div className="image-container">
        <img src={campaign.images} alt={campaign.name} />
        <div className="donor-count">{formatDonors(campaign.number_of_donors)}</div>
      </div>
      <div className="campaign-info">
        <h3>{campaign.name}</h3>
        <div className="campaign-progress">
          <div className="progress-bar">
            <div 
              className="progress" 
              style={{width: `${(campaign.amount_raised / campaign.goal) * 100}%`}}
            ></div>
          </div>
          <p className="amount-raised">£{campaign.amount_raised.toLocaleString()} raised</p> 
        </div>
      </div>
    </Link>
  );
};

export default CampaignCard;
