import { useNavigate } from 'react-router-dom';

function DonateSection() {
  const navigate = useNavigate();

  const handleGiveOutClick = () => {
    navigate('/giveout'); 
  };

  return (
    <div className="donate-section">
      <div className="donate-text-content">
        <h2>Share Your Unused Treasures</h2>
        <p>
          Every item you no longer need can be a beacon of hope for someone
          else. By sharing what you no longer use, you are not just decluttering
          your space; you are giving someone a chance to start anew, to find joy
          in something they couldn't have otherwise. Let's make a difference
          together your kindness can change lives. Click below to give out your
          items and spread some love.
        </p>
        <button className="give-out-button" onClick={handleGiveOutClick}>
          Give Out
        </button>
      </div>
      <div className="donate-image-content">
        <img
          src={require("../images/Out.jpeg")}
          alt="Donate items"
          className="donate-image"
        />
      </div>
    </div>
  );
}

export default DonateSection;
