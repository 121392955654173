function Profile({ donors }) {
  
    return (
      <div id="profile">
        {donors.map((donor, index) => (
          <Item key={index} donor={donor} rank={index + 1} />
        ))}
      </div>
    );
  }
  
  function Item({ donor, rank }) {
    return (
      <div className="flex">
        <div className="item">
          <div className="rank">{rank}</div>
          <img src={donor.avatar} alt="avatar" />
          <div className="info">
            <h3 className="name">{donor.anonymous_name || 'Anonymous'}</h3>
          </div>
        </div>
        <div className="donation-amount">
          <span>£{donor.total_donated.toFixed(2)}</span> 
        </div>
      </div>
    );
  }
  export default Profile;