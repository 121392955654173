import React, { useEffect, useState } from "react";
import supabase from "../config/supabaseClient";
import PopularCampaigns from "./PopularCampaigns";

function Card() {
  const [fetchError, setFetchError] = useState(null);
  const [campaigns, setCampaigns] = useState(null);

  useEffect(() => {
    const fetchCampaigns = async () => {
      const { data, error } = await supabase.from('campaign').select();

      if (error) {
        setFetchError('Could not fetch campaigns');
        setCampaigns(null);
        console.log(error);
      }
      if (data) {
        setCampaigns(data);
        setFetchError(null);
      }
    };

    fetchCampaigns();
  }, []);

  return (
    <div>
      {fetchError && <p>{fetchError}</p>}
      {campaigns && <PopularCampaigns campaigns={campaigns} />}
    </div>
  );
}

export default Card;