import React, { useEffect, useState } from "react";
import supabase from "../config/supabaseClient";
import GiveOutCard from "../components/GiveOutCard";
import NavigationBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import '../giveout.css'; 

function AllGiveOut({ token, setToken }) {
  const [fetchError, setFetchError] = useState(null);
  const [giveouts, setGiveOuts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9; 

  useEffect(() => {
    const fetchGiveOut = async () => {
      const { data, error } = await supabase.from("giveout").select();

      if (error) {
        setFetchError("Could not fetch the giveouts");
        setGiveOuts([]);
        console.log(error);
      }
      if (data) {
        setGiveOuts(data);
        setFetchError(null);
      }
    };

    fetchGiveOut();
  }, []);

  const totalPages = Math.ceil(giveouts.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentGiveOuts = giveouts.slice(startIndex, startIndex + itemsPerPage);

  return (
    <div className="all-giveout-page">
      <NavigationBar token={token} setToken={setToken} />
      <br></br>
      {fetchError && <p>{fetchError}</p>}
      <div className="all-giveout-grid">
        {currentGiveOuts.map((giveout) => (
          <GiveOutCard key={giveout.id} giveout={giveout} />
        ))}
      </div>
      <div className="pagination">
        <button 
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &laquo; Previous
        </button>
        <span className="current-page">{currentPage}</span>
        <button 
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next &raquo;
        </button>
      </div>
      <Footer />
    </div>
  );
}

export default AllGiveOut;
