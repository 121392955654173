import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

function NavigationBar({ token, setToken }) {
    const navigate = useNavigate();

    const handleLogout = () => {
        setToken(null);
        navigate('/auth');
    };

    const handleStartCampaign = () => {
        if (token) {
            navigate('/campaign');
        } else {
            sessionStorage.setItem('intendedDestination', '/campaign');
            navigate('/auth');
        }
        
    };

    return (
        <nav className="custom-navbar">
            <div className="custom-navbar-left">
                <Link to="/my-page" className="custom-nav-link custom-search-btn">
                    <img
                        src={require("../images/Search.png")}
                        alt="My Page"
                        className="custom-icon"
                    />
                    My Page
                </Link>
                <Link to="/leaderboard" className="custom-nav-link custom-leaderboard-btn">
                    <img
                        src={require("../images/Leaderboard.png")}
                        alt="Leaderboard"
                        className="custom-icon"
                    />
                    Leaderboard
                </Link>
            </div>
            <div className="custom-navbar-center">
                <Link to="/" className="custom-logo">DonatingHub</Link>
            </div>
            <div className="custom-navbar-right">
                {token ? (
                    <button className="custom-nav-link custom-logout-btn" onClick={handleLogout}>
                        Logout
                    </button>
                ) : (
                    <Link to="/auth" className="custom-nav-link custom-login-btn">
                        Login/Sign up
                    </Link>
                )}
                <button onClick={handleStartCampaign} className="custom-campaign-btn">Start a Campaign</button>
            </div>
        </nav>
    );
}

export default NavigationBar;