import "./App.css";
import React, { useState, useEffect } from "react";
import HomePage from "./pages/Homepage";
import ErrorPage from "./pages/Errorpage";
import AuthPage from "./pages/Authpage";
import CardSection from "./pages/CardSection";
import Leaderboard from "./pages/Leaderboard";
import CampaignPage from "./pages/CampaignPage";
import AllCampaignsPage from './pages/AllCampaignsPage';
import GiveOutForm from './pages/GiveOutForm';
import GiveOutSection from './pages/GiveOutSection';
import AllGiveOut from "./pages/AllGiveOut";
import AdminPage from "./pages/AdminPage";
import AdminGiveoutPage from "./pages/AdminGiveoutPage";  
import MyPage from "./pages/MyPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

function App() {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const storedToken = sessionStorage.getItem('token');
    if (storedToken) {
      setToken(JSON.parse(storedToken));
    }
  }, []);

  useEffect(() => {
    if (token) {
      sessionStorage.setItem('token', JSON.stringify(token));
    } else {
      sessionStorage.removeItem('token');
    }
  }, [token]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage token={token} setToken={setToken} />,
    },
    {
      path: "/campaign/:campaignId",
      element: <CardSection token={token} setToken={setToken} />,
    },
    {
      path: "/error",
      element: <ErrorPage />,
    },
    {
      path: "/auth",
      element: <AuthPage setToken={setToken} />,
    },
    {
      path: "/leaderboard",
      element: (
        <div id="main">
          <Leaderboard setToken={setToken} />
        </div>
      ),
    },
    {
      path: "/campaign",
      element: <CampaignPage token={token} />,
    },
    {
      path: "/campaigns",
      element: <AllCampaignsPage token={token} setToken={setToken} />,
    },
    {
      path: "/giveout",
      element: <GiveOutForm token={token} setToken={setToken} />,
    },
    {
      path: "/giveout/:giveoutId",
      element: <GiveOutSection token={token} setToken={setToken} />,
    },
    {
      path: "/all-giveouts",
      element: <AllGiveOut token={token} setToken={setToken} />,
    },
    {
      path: "/admin",
      element: <AdminPage token={token} setToken={setToken} />,
    },
    {
      path: "/my-page",
      element: <MyPage token={token} />,
    },
    {
      path: "/admin-giveouts",  
      element: <AdminGiveoutPage token={token} setToken={setToken} />,  
    }
  ]);

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
