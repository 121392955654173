import React, { useState, useEffect } from 'react';
import { Upload } from 'lucide-react';
import axios from 'axios';

const EditCampaignModal = ({ campaign, onClose, onSave }) => {
    const [formData, setFormData] = useState({
        name: '',
        images: '',
        goal: '',
        statement: '',
        country: '',
    });

    useEffect(() => {
        if (campaign) {
            setFormData({
                name: campaign.name || '',
                images: campaign.images || '',
                goal: campaign.goal || '',
                statement: campaign.statement || '',
                country: campaign.country || '',
            });
        }
    }, [campaign]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageFormData = new FormData();
            imageFormData.append('file', file);
            imageFormData.append("upload_preset", "avatar");

            try {
                const response = await axios.post(
                    "https://api.cloudinary.com/v1_1/dwyorh97f/image/upload",
                    imageFormData
                );
                setFormData(prevData => ({
                    ...prevData,
                    images: response.data.secure_url,
                }));
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave({ ...campaign, ...formData });
    };

    if (!campaign) return null;

    return (
        <div className="modal">
            <div className="modal-content">
                <h2>Edit Campaign</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-grid">
                        <div className="form-left">
                            <label>
                                Name:
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                            <label>
                                Goal:
                                <input
                                    type="text"
                                    name="goal"
                                    value={formData.goal}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                            <label>
                                Description:
                                <textarea
                                    name="statement"
                                    value={formData.statement}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                            <label>
                                Country:
                                <input
                                    type="text"
                                    name="country"
                                    value={formData.country}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                        </div>
                        <div className="form-right">
                            <label className="image-upload">
                                Campaign Image:
                                <div className="image-upload-area">
                                    {formData.images ? (
                                        <img src={formData.images} alt="Campaign" className="uploaded-image" />
                                    ) : (
                                        <div className="upload-placeholder">
                                            <Upload size={24} />
                                            <span>Drag or upload your photo here</span>
                                        </div>
                                    )}
                                    <input
                                        type="file"
                                        name="images"
                                        onChange={handleImageUpload}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="button-group">
                        <button type="button" className="cancel-button" onClick={onClose}>
                            Cancel
                        </button>
                        <button type="submit" className="save-button">
                            Save Changes
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditCampaignModal;