import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faGooglePlusG, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import supabase from '../config/supabaseClient';
import { useNavigate, useSearchParams } from 'react-router-dom';

function AuthPage({ setToken }) {
    const containerRef = useRef(null);
    const overlayBtnRef = useRef(null);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [showSignUpPassword, setShowSignUpPassword] = useState(false);
    const [showSignInPassword, setShowSignInPassword] = useState(false);

    useEffect(() => {
        const container = containerRef.current;
        const overlayBtn = overlayBtnRef.current;

        const handleOverlayBtnClick = () => {
            container.classList.toggle('right-panel-active');
            overlayBtn.classList.remove('btnScaled');
            window.requestAnimationFrame(() => {
                overlayBtn.classList.add('btnScaled');
            });
        };

        overlayBtn.addEventListener('click', handleOverlayBtnClick);

        return () => {
            overlayBtn.removeEventListener('click', handleOverlayBtnClick);
        };
    }, []);

    const [signUpFormData, setSignUpFormData] = useState({
        fullName: '', email: '', password: ''
    });

    const [signInFormData, setSignInFormData] = useState({
        email: '', password: ''
    });

    function handleSignUpChange(event) {
        setSignUpFormData((prevFormData) => ({
            ...prevFormData,
            [event.target.name]: event.target.value
        }));
    }

    function handleSignInChange(event) {
        setSignInFormData((prevFormData) => ({
            ...prevFormData,
            [event.target.name]: event.target.value
        }));
    }

    const toggleSignUpPassword = () => setShowSignUpPassword(!showSignUpPassword);
    const toggleSignInPassword = () => setShowSignInPassword(!showSignInPassword);

    async function handleSignUpSubmit(e) {
        e.preventDefault();
        try {
            const { data, error } = await supabase.auth.signUp({
                email: signUpFormData.email,
                password: signUpFormData.password,
                options: {
                    data: {
                        full_name: signUpFormData.fullName,
                    }
                }
            });
            if (error) throw error;
            alert('Check your email for verification link');
        } catch (error) {
            alert(error.message);
        }
    }

    async function handleSignInSubmit(e) {
        e.preventDefault();
        try {
            const { data, error } = await supabase.auth.signInWithPassword({
                email: signInFormData.email,
                password: signInFormData.password,
            });
            if (error) throw error;

            const { data: { user }, error: userError } = await supabase.auth.getUser();
            if (userError) throw userError;

            const redirectPath = sessionStorage.getItem('intendedDestination') || '/';

            setToken({
                accessToken: data.session.access_token,
                userId: user.id, 
                name: user.user_metadata.full_name 
            });

            sessionStorage.removeItem('intendedDestination'); 

            navigate(redirectPath);
        } catch (error) {
            alert(error.message);
        }
    }

    return (
        <div className="auth-container" id="container" ref={containerRef}>
            <div className="auth-form-container sign-up-container">
                <form className="auth-form" onSubmit={handleSignUpSubmit}>
                    <h1>Create Account</h1>
                    <div className="social-container">
                        <a href="#" className="social"><FontAwesomeIcon icon={faFacebookF} /></a>
                        <a href="#" className="social"><FontAwesomeIcon icon={faGooglePlusG} /></a>
                        <a href="#" className="social"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                    </div>
                    <span>or use your email for registration</span>
                    <div className="infield">
                        <input type="text" placeholder="Name" name="fullName" onChange={handleSignUpChange} required/>
                        <label></label>
                    </div>
                    <div className="infield">
                        <input type="email" placeholder="Email" name="email" onChange={handleSignUpChange} />
                        <label></label>
                    </div>
                    <div className="infield">
                        <input 
                            type={showSignUpPassword ? "text" : "password"} 
                            placeholder="Password" 
                            name="password" 
                            onChange={handleSignUpChange} 
                            minLength="6"
                        />
                        <FontAwesomeIcon 
                            icon={showSignUpPassword ? faEyeSlash : faEye} 
                            onClick={toggleSignUpPassword}
                            className="password-toggle-icon"
                        />
                        <label></label>
                    </div>
                    <button>Sign Up</button>
                </form>
            </div>

            <div className="auth-form-container sign-in-container">
                <form className="auth-form" onSubmit={handleSignInSubmit}>
                    <h1>Sign in</h1>
                    <div className="social-container">
                        <a href="#" className="social"><FontAwesomeIcon icon={faFacebookF} /></a>
                        <a href="#" className="social"><FontAwesomeIcon icon={faGooglePlusG} /></a>
                        <a href="#" className="social"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                    </div>
                    <span>or use your account</span>
                    <div className="infield">
                        <input type="email" placeholder="Email" name="email" onChange={handleSignInChange} />
                        <label></label>
                    </div>
                    <div className="infield">
                        <input 
                            type={showSignInPassword ? "text" : "password"} 
                            placeholder="Password" 
                            name="password" 
                            onChange={handleSignInChange} 
                        />
                        <FontAwesomeIcon 
                            icon={showSignInPassword ? faEyeSlash : faEye} 
                            onClick={toggleSignInPassword}
                            className="password-toggle-icon"
                        />
                        <label></label>
                    </div>
                    <a href="#" className="forgot">Forgot your password?</a>
                    <button>Sign In</button>
                </form>
            </div>

            <div className="overlay-container" id="overlayCon">
                <div className="overlay">
                    <div className="overlay-panel overlay-left">
                        <h1>Welcome Back!</h1>
                        <p>To keep connected with us please login with your personal info</p>
                        <button onClick={() => containerRef.current.classList.remove('right-panel-active')}>Sign In</button>
                    </div>
                    <div className="overlay-panel overlay-right">
                        <h1>Hello, Friend!</h1>
                        <p>Enter your personal details and start your journey with us</p>
                        <button onClick={() => containerRef.current.classList.add('right-panel-active')}>Sign Up</button>
                    </div>
                </div>
                <button id="overlayBtn" ref={overlayBtnRef}></button>
            </div>
        </div>
    );
}

export default AuthPage;
