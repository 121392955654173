import supabase from "../config/supabaseClient";

const submitDonation = async (donation) => {
  const { email, amount, avatar, anonymousName, campaignId } = donation;

  // Insert the new donation
  const { error: insertError } = await supabase
    .from('donations')
    .insert([
      {
        donor_email: email,
        donation_amount: amount,
        donor_avatar: avatar,
        anonymous_name: anonymousName,
        campaign_id: campaignId,
      }
    ]);

  if (insertError) {
    console.error("Error inserting donation:", insertError);
    return;
  }

  // Update the donor_totals table
  const { data, error: selectError } = await supabase
    .from('donor_totals')
    .select('total_donated')
    .eq('donor_email', email)
    .single();

  if (selectError && selectError.code !== 'PGRST116') {
    console.error("Error fetching donor total:", selectError);
    return;
  }

  const currentTotal = data ? data.total_donated : 0;
  const newTotal = currentTotal + amount;

  const { error: upsertError } = await supabase
    .from('donor_totals')
    .upsert({
      donor_email: email,
      total_donated: newTotal,
      avatar: avatar,
      anonymous_name: anonymousName,
    }, { onConflict: ['donor_email'] });

  if (upsertError) {
    console.error("Error updating donor totals:", upsertError);
  }

  // Update the campaign's donor count
  const { data: campaignData, error: campaignError } = await supabase
    .from('campaign')
    .select('number_of_donors')
    .eq('id', campaignId)
    .single();

  if (campaignError) {
    console.error("Error fetching campaign data:", campaignError);
    return;
  }

  const newDonorCount = (campaignData ? campaignData.number_of_donors : 0) + 1;

  const { error: updateCampaignError } = await supabase
    .from('campaign')
    .update({ number_of_donors: newDonorCount })
    .eq('id', campaignId);

  if (updateCampaignError) {
    console.error("Error updating campaign donor count:", updateCampaignError);
  }
};

export default submitDonation;
