import React, { useState, useEffect } from "react";
import axios from "axios";
import supabase from "../config/supabaseClient";

const DonationModal = ({ isOpen, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    anonymousName: "",
    email: "",
    amount: "",
    avatar: "",
  });

  const [imageSelected, setImageSelected] = useState(null);
  const [totalDonated, setTotalDonated] = useState(0);

  useEffect(() => {
    const fetchTotalDonation = async () => {
      if (formData.email) {
        const { data, error } = await supabase
          .from("donor_totals")
          .select("total_donated")
          .eq("donor_email", formData.email)
          .single();

        if (error) {
          console.error("Error fetching total donation:", error);
        } else if (data) {
          setTotalDonated(data.total_donated);
        }
      }
    };

    fetchTotalDonation();
  }, [formData.email]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "amount") {
      const amount = value === "" ? "" : Math.min(Number(value), 10000);
      setFormData({
        ...formData,
        amount: amount,
      });
    } else {
      setFormData({
        ...formData,
        [name]: files ? files[0] : value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let avatarUrl = null;

    if (imageSelected) {
      avatarUrl = await uploadImage(imageSelected);
      if (!avatarUrl) {
        alert("Image upload failed. Please try again.");
        return;
      }
    }

  
    onSubmit({ ...formData, avatar: avatarUrl });
  };

  const uploadImage = async (file) => {
    const imageFormData = new FormData();
    imageFormData.append("file", file);
    imageFormData.append("upload_preset", "avatar");

    try {
      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/dwyorh97f/image/upload",
        imageFormData
      );
      return response.data.secure_url;
    } catch (error) {
      console.error("Error uploading image:", error);
      return null;
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-header">
          <h2>Make a Donation</h2>
          <button className="modal-close-btn" onClick={onClose}>
            &times;
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            <input
              type="text"
              name="anonymousName"
              placeholder="Anonymous Name"
              className="modal-input"
              value={formData.anonymousName}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="modal-input"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="number"
              name="amount"
              placeholder="Donation Amount (Max £10,000)"
              className="modal-input"
              value={formData.amount}
              onChange={handleChange}
              required
              min="0"
              max="10000" 
            />
            <label htmlFor="avatar" className="avatar-label">Avatar</label>
            <input
              type="file"
              name="avatar"
              className="modal-input"
              onChange={(e) => {
                setImageSelected(e.target.files[0]);
              }}
            />
            <p>Total Donations: £{totalDonated.toFixed(2)}</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="modal-cancel-btn"
              onClick={onClose}
            >
              Cancel
            </button>
            <button type="submit" className="modal-submit-btn">
              Donate
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DonationModal;
